import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const KontaktPage = () => (
    <Layout>
        <SEO title="Kontakt" />
        <div className="mainWrapper">
            <h1>Kontakta oss</h1>
            <div className="contactForm">
            <form name="contact" method="post" data-netlify="true" action="/">
              <input type="hidden" name="form-name" value="contact" />
              <input name="name" placeholder="Namn" type="text"/>
              <input name="organization" placeholder="Företag" type="text"/>
              <input name="email" placeholder="Mail" type="email"/>
              <textarea name="message" placeholder="Meddelande..."></textarea>
              <button className="button" type="submit">Skicka</button>
            </form>
          </div>
        </div>
    </Layout>
)
export default KontaktPage